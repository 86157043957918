import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducer";
import Routes from "constants/routes";

// import * as S from './style';

interface Props {
  listData?: any;
  selected?: any;
}

function Listitems({ listData, selected }: Props): React.ReactElement {
  // const [subSelected, setSubSelected] = useState("");
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    // setSubSelected(location.pathname)
  };

  const subSelected = location.pathname;
  const dataBegin = useSelector(
    (state: RootState) => state.notification?.dataBegin
  );

  // useEffect(() => {
  //   setSubSelected(location.pathname)
  // }, [])

  return (
    <ListItem
      button
      className={`${
        selected === listData.link ? "active" : "unActive"
      } list-item`}
    >
      {listData.subMenu !== null ? (
        <Link
          to={listData.link}
          className="navigate-menu"
          onClick={handleClick}
        >
          <ListItemIcon>{listData.icon}</ListItemIcon>
          <ListItemText className="hiden-on-close">
            {listData.titleMenu}
          </ListItemText>
          {open ? (
            <ArrowDropUpIcon className="hiden-on-close arrow" />
          ) : (
            <ArrowDropDownIcon className="hiden-on-close arrow" />
          )}
        </Link>
      ) : (
        <Link to={listData.link} className="navigate-menu">
          <ListItemIcon>{listData.icon}</ListItemIcon>
          <ListItemText className="hiden-on-close">
            {listData.titleMenu}
          </ListItemText>
          {listData?.link === Routes.ADMIN_LIST_BUY_COIN && (
            <span className="notification">
              {dataBegin?.total_buy_coin_unread || 0}
            </span>
          )}
          {listData?.link === Routes.ADMIN_SELL_COIN_REGISTRATION && (
            <span className="notification">
              {dataBegin?.total_sell_coin_unread || 0}
            </span>
          )}
        </Link>
      )}
      <div className="list-subMenu" style={{ padding: "0 15px" }}>
        {
          // listData.isActive &&
          listData.subMenu && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {listData.subMenu.map((submenuItem, i) => {
                return (
                  <ListItem
                    button
                    className={`${
                      subSelected === submenuItem.link ? "active" : "unActive"
                    } list-item`}
                    key={i}
                  >
                    <Link
                      to={submenuItem.link}
                      className="sub-info navigate-menu"
                    >
                      <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                      <ListItemText className="hiden-on-close sub-info-item">
                        {submenuItem.titleMenu}
                      </ListItemText>
                      {submenuItem?.link === Routes.ADMIN_PENDING_MEMBER && (
                        <span className="notification">
                          {dataBegin?.total_new_user_unread || 0}
                        </span>
                      )}
                    </Link>
                  </ListItem>
                );
              })}
            </Collapse>
          )
        }
      </div>
    </ListItem>
  );
}

export default Listitems;
