import styled from 'styled-components'
import BgSideBar from 'assets/images/sidebar-2.jpg';

export const Sidebar = styled.div`
.sidebar{
  width: 260px;
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
  .logo-icon{
    margin-right: 7px;
    margin-left: 7px;
    width: 34px;
  }
  > .MuiDrawer-paper{
    width: 260px;
    overflow: hidden;
    transform: none !important;
    visibility: visible !important;
    border: none;
    box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    transition: all 0.3s !important;
    &:before,
    &:after{
      top: 0;
      width: 260px;
      height: 100%;
      content: "";
      display: block;
      z-index: 3;
      position: absolute;
      left: 0;
    }
    &:before{
      background-image: url(${BgSideBar});
      background-size: cover;
      background-position: center center;
      z-index: 1;
    }
    &:after{
      opacity: .8;
      background: #000;
    }
  }

  .sidebar-scroll{
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 70px);
    &> .scrollbar-container{
      padding: 0 15px;
      scroll-behavior: smooth;
      height: auto;
    }
  }

  .sidebar-content{
    z-index: 9;
    height: 100%;
  }

  .user-info{
    padding: 20px 0;
    border-bottom: 1px solid hsla(0,0%,100%,.3);
  }
    .sub-info {
      display: block;
      align-items: center;
      color: #ffffff;
      margin-top: 10px;
      height: 40px;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      &.active {
        box-shadow: none;
        background-color: rgba(200, 200, 200, 0.2);
        outline: none;
      }
      &:hover {
        outline: none;
        box-shadow: none;
        background-color: rgba(200, 200, 200, 0.2);
      }
      .code-name {
        text-align: center;
        font-size: 14px;
        width: 30px;
        margin-right: 15px;
        float: left;
        line-height: 1.5em;
      }
      .sub-info-item{
        margin: 0;
        line-height: 1.5em;
      }
    }

  .user-info-top{
    display: flex;
    align-items: center;
    position: relative;
    .avatar{
      height: 34px;
      width: 34px;
      border-radius: 50%;
      margin-left: 8px;
      margin-right: 11px;
    }
    .icon-arrow {
      color: #ffffff;
      position: absolute;
      right: 10px;
      top: 0;
      height: 34px;
      line-height: 34px;
    }
    span{
      font-size: 14px;
      color: #ffffff;
    }
  }
  .sidebar-head{
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid hsla(0,0%,100%,.3);
    margin: 0 15px;
    .logo{
      display: inline-block;
      img{
        height: 30px;
      }
    }
  }
  .menu-list{
    .list-item{
      margin-top: 10px;
      flex-direction: column;
      padding: 0;
      min-height: 50px;
      /* &:first-child{
        .navigate-menu{
          box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%) !important;
          background-color: #00acc1 !important;
        }
      } */
      .navigate-menu {
        display: flex;
        text-decoration: none;
        font-size: 18px;
        align-items: center;
        color: #ffffff;
        font-weight: bold;
        svg{
          color: #ffffff;
        }
      }
      &.active{
        .navigate-menu{
          /* background-color: rgba(200, 200, 200, 0.2); */
          box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%) !important;
          background-color: #00acc1 !important;
        }
      }
    }
    .navigate-menu{
      padding: 10px 30px 10px 15px;
      width: 100%;
      border-radius: 3px;
      display: flex;
      align-items: center;
      position: relative;
      .MuiListItemIcon-root{
        min-width: auto;
        margin-right: 15px;
      }
      .arrow{
        position: absolute;
        top: 0;
        right: 20px;
        height: 50px;
      }
      &:hover{
        background-color: rgba(200, 200, 200, 0.2);
        color: #ffffff;
      }
    }
    .list-subMenu{
      width: 100%;
      .submenu{
        display: flex;
        align-items: center;
      }
    }
  }

  .investors{
    margin-top: 20px;
    /* padding-bottom: 20px;
    border-bottom: 1px solid hsla(0,0%,100%,.3); */
  }

  .hiden-on-close{
    position: relative;
    white-space: nowrap;
    transform: translate3d(0px, 0, 0);
    transition: all 0.3s ease-in-out;
    span{
      white-space: nowrap;
    }
  }
  
  &.close{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-shadow: none;
    &:not(:hover){
      width: 80px; 
      > .MuiDrawer-paper{
        width: 80px;
      } 
      .hiden-on-close{
        opacity: 0;
        transform: translate3d(-25px, 0, 0);
        visibility: hidden;
      }
    }
  }
}
`