import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../redux/store";

import { getBeginNotice, getTotalElement } from "services/User";
import Routes from "constants/routes";

interface FilterState {
  dateFilter: {
    list_buy_coin_date: string;
    sell_coin_registration_date: string;
    bank_account_date: string;
  };
}

const initialState: FilterState = {
  dateFilter: {
    list_buy_coin_date: null,
    sell_coin_registration_date: null,
    bank_account_date: null,
  },
};

const filter = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setDateFilter(state: any, action) {
      state.dateFilter = action.payload;
    },
  },
});

export const { setDateFilter } = filter.actions;

export default filter.reducer;
