import React, { useState, Fragment, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-perfect-scrollbar/dist/css/styles.css";

import Routes from "constants/routes";
import { logOut } from "features/user/userSlice";
import reactLocalStorage from "utils/localStorage";

import { Drawer, List, ListItemText } from "@material-ui/core";
import { ListItems, PopupAlert } from "components";

import * as S from "./style";
import {
  getDataBegin,
  getDataTotal,
  setDataBegin,
} from "features/user/notificationSlice";
import { RootState } from "redux/reducer";
import usePrevious from "hooks/usePrevious";
import NotificationSound from "assets/sounds/notification_mp3.mp3";
import NoticeNewUser from "assets/sounds/notice_new_user.mp3";
import { useTranslation } from "react-i18next";
import { readBuyCoin, readNewUser, readSellCoin } from "services/User";
interface Props {
  userInfo?: any;
  sideBarMenu?: any;
  open?: boolean;
}

function SideBar(props: Props) {
  const { userInfo, sideBarMenu, open } = props;
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const location = useLocation();
  const [userInfoAvtive, setUserInfoAvtive] = useState(false);
  const dataBegin = useSelector(
    (state: RootState) => state.notification?.dataBegin
  );
  const previousValue: any = usePrevious(dataBegin);
  const [isOpenLogoutPopup, setOpenLogoutPopup] = useState(false);
  const { t }: { t: any } = useTranslation("common");

  const handleActiveUserInfo = () => {
    setUserInfoAvtive(!userInfoAvtive);
  };

  const handleReadBuyCoin = async () => {
    try {
      const resp: any = await readBuyCoin();
      if (resp?.result) {
        dispatch(getDataBegin());
      }
    } catch (error) {
      console.log("handleReadBuyCoin error", error);
    }
  };

  const handleReadSellCoin = async () => {
    try {
      const resp: any = await readSellCoin();
      if (resp?.result) {
        dispatch(getDataBegin());
      }
    } catch (error) {
      console.log("handleReadBuyCoin error", error);
    }
  };

  const handleReadNewUser = async () => {
    try {
      const resp: any = await readNewUser();
      if (resp?.result) {
        dispatch(getDataBegin());
      }
    } catch (error) {
      console.log("handleReadNewUser error", error);
    }
  };

  const resetDataNotification = () => {
    handleReadBuyCoin();
    handleReadSellCoin();
    handleReadNewUser();
    dispatch(
      setDataBegin({
        total_buy_coin_unread: 0,
        total_sell_coin_unread: 0,
        total_new_user_unread: 0,
      })
    );
  };

  if (
    dataBegin?.total_buy_coin_unread > previousValue?.total_buy_coin_unread ||
    dataBegin?.total_sell_coin_unread > previousValue?.total_sell_coin_unread
  ) {
    // new Audio(NotificationSound).play();
    // setTimeout(() => {
    //   resetDataNotification();
    // }, 3000);
    // window.location.reload();
    const audio = new Audio(NotificationSound);

    // Try to play the audio
    audio
      .play()
      .then(() => {
        // If audio playback starts successfully, trigger other actions
        console.log("Audio started playing");
        setTimeout(() => {
          resetDataNotification();
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        // If audio playback fails, log the error but still proceed with the other actions
        console.error("Audio playback failed:", error);
        setTimeout(() => {
          resetDataNotification();
          window.location.reload();
        }, 3000);
      });
  }

  if (dataBegin?.total_new_user_unread > previousValue?.total_new_user_unread) {
    // new Audio(NoticeNewUser).play();
    // setTimeout(() => {
    //   resetDataNotification();
    // }, 3000);
    // window.location.reload();

    const audio = new Audio(NoticeNewUser);

    // Try to play the audio
    audio
      .play()
      .then(() => {
        // If audio playback starts successfully, trigger other actions
        console.log("Audio started playing");
        setTimeout(() => {
          resetDataNotification();
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        // If audio playback fails, log the error but still proceed with the other actions
        console.error("Audio playback failed:", error);
        setTimeout(() => {
          resetDataNotification();
          window.location.reload();
        }, 3000);
      });
  }

  const onLogOut = () => {
    dispatch(logOut());
    reactLocalStorage.clear();
    setOpenLogoutPopup(false);
    history.push(Routes.LOGIN);
    window.location.reload();
  };

  const selected = location.pathname;
  // console.log("location.pathname", location.pathname)

  useEffect(() => {
    // dispatch(getDataBegin());
    setInterval(() => {
      // dispatch(getDataTotal());
      dispatch(getDataBegin());
    }, 10000);
  }, []);

  return (
    <S.Sidebar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className={`sidebar ${open === true ? "open" : "close"}`}
      >
        <div className="sidebar-content">
          <div className="sidebar-scroll">
            <PerfectScrollbar>
              <div className="user-info">
                <Link
                  to="#"
                  className="user-info-top"
                  onClick={() => handleActiveUserInfo()}
                >
                  <img className="avatar" src={userInfo.avtSrc} alt="avatar" />
                </Link>
                {userInfo.subInfo.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={item.link}
                      className="sub-info"
                      onClick={() => setOpenLogoutPopup(true)}
                    >
                      <span className="code-name">{item.codeName}</span>
                      <ListItemText className="hiden-on-close sub-info-item">
                        {item.pageName}
                      </ListItemText>
                    </Link>
                  );
                })}
              </div>

              <List className="menu-list" style={{ padding: 0 }}>
                {sideBarMenu &&
                  sideBarMenu.map((item?: any, index?: number) => {
                    return (
                      <Fragment key={index}>
                        <ListItems selected={selected} listData={item} />
                      </Fragment>
                    );
                  })}
              </List>
            </PerfectScrollbar>
          </div>
        </div>
      </Drawer>
      {isOpenLogoutPopup && (
        <PopupAlert
          isCancel={true}
          isConfirm={true}
          titlePopup={t("CONFIRM_LOGOUT")}
          handleCancel={() => setOpenLogoutPopup(false)}
          handleConfirm={onLogOut}
        />
      )}
    </S.Sidebar>
  );
}

export default SideBar;
