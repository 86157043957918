export const MoneyConvert = (value: any) => {
  if (value === undefined || value === null || value === "") return 0;
  if (Number.isInteger(Number(value)))
    return Number(value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return Number(value)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export function onNumericInputChange(value: any) {
  const reg = /^-?\d*(\.\d*)?$/;
  if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
    return value;
  }
  return false;
}
