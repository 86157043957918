import React, { useState, memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
// import { useWeb3React } from '@web3-react/core'

import { AppBar, Toolbar, Button, IconButton } from "@material-ui/core";

import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CloseIcon from "@material-ui/icons/Close";

import Routes from "constants/routes";
import { logOut } from "features/user/userSlice";
import reactLocalStorage from "utils/localStorage";

import * as S from "./style";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "hooks/useOnClickOutside";
import PopupAlert from "components/PopupAlert";

const listOptions = [
  {
    src: "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg",
    name: "Korea",
    lang: "kr",
  },
  {
    src: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg",
    name: "English",
    lang: "en",
  },
];
interface Props {
  pageHeading?: any;
  handleDrawerOpen(): any;
  isOpen?: boolean;
}

function Header(props: Props) {
  const {
    // pageHeading,
    handleDrawerOpen,
    isOpen,
  } = props;
  const { i18n } = useTranslation("common");
  // const { account } = useWeb3React()
  const { t }: { t: any } = useTranslation("common");
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [langActive, setLangActive] = useState(listOptions[0]);
  const langRef = useRef();
  useOnClickOutside(langRef, () => setActiveDropdown(false));
  const [isOpenLogoutPopup, setOpenLogoutPopup] = useState(false);

  const onLogOut = () => {
    dispatch(logOut());
    reactLocalStorage.clear();
    setOpenLogoutPopup(false);
    history.push(Routes.LOGIN);
    window.location.reload();
  };

  const onHandleClick = (item) => {
    setLangActive(item);
    i18n.changeLanguage(item.lang);
    setActiveDropdown(false);
  };

  return (
    <S.Header>
      <AppBar position="fixed" className="app-bar">
        <Toolbar className="navbar-dashboard">
          <div className="left-navbar">
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`btn-action-sidebar ${
                isOpen === true ? "btn-open" : "btn-close"
              }`}
            >
              {isOpen === true ? (
                <FormatListBulletedIcon className="arrow" />
              ) : (
                <MoreVertIcon className="arrow" />
              )}
            </IconButton>
          </div>

          <div className="right-navbar">
            {/* <Button
              variant="contained"
              color="primary"
              className="icon-heading"
              onClick={() => new Audio(NotificationSound).play()}
            >
              <NotificationsIcon />
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              className="icon-heading"
              onClick={() => setOpenLogoutPopup(true)}
            >
              <PersonIcon />
            </Button>
            <S.Languages ref={langRef}>
              <div className="language">
                <div
                  className="language-block"
                  onClick={() => setActiveDropdown(!activeDropdown)}
                >
                  {/* <span className="language-title">Languages:</span> */}
                  <img src={langActive.src} alt="language" />
                </div>
                {activeDropdown ? (
                  <ul className="pop-content">
                    <li
                      className="close-btn"
                      onClick={() => setActiveDropdown(!activeDropdown)}
                    >
                      <CloseIcon />
                    </li>

                    {listOptions.map((item, index) => {
                      return (
                        <li key={index}>
                          <button onClick={() => onHandleClick(item)}>
                            <img src={item.src} alt={item.name} />
                            <span>{item.name}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </S.Languages>
          </div>
        </Toolbar>
      </AppBar>
      {isOpenLogoutPopup && (
        <PopupAlert
          isCancel={true}
          isConfirm={true}
          titlePopup={t("CONFIRM_LOGOUT")}
          handleCancel={() => setOpenLogoutPopup(false)}
          handleConfirm={onLogOut}
        />
      )}
    </S.Header>
  );
}

export default memo(Header);
