import axios from "services/axiosInstance";
import Endpoints from "constants/endpoints";

export async function getAllDistribute(formData?: any) {
  return await axios()
    .post(Endpoints.GET_DISTRIBUTE_BY_COMPANY, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function getBalance(formData?: any) {
  return await axios()
    .post(Endpoints.GET_DISTRIBUTE_BALANCE, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function getPurchaseInfo(formData?: any) {
  return await axios()
    .post(Endpoints.GET_USER, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}