import React, { useState } from "react";
import RefreshIcon from '@material-ui/icons/Refresh';
import { getBalance } from "services/DistributeHistory/index";
import * as S from './style';
import { MoneyConvert } from "utils/unit";

interface Props {
  address?: string;
}

function CheckBalance(props: Props) {
  const {address} = props;
  const [balance, setBalance] = useState("");
  // const trade_krw = 1000;

  const checkBalance = async (params?: any) => {
    let defaultParams = {
      address: address,
      ...params,
    };
    let resp = await getBalance(defaultParams)
    console.log("balance", resp);
    setBalance(resp?.data ?? "")
  }

  const handleCheckBalance = () => {
    checkBalance()
  }
  return (
    <S.CheckBalance>
      {balance ? MoneyConvert(balance) : "..."} oUSDT
      <RefreshIcon onClick={handleCheckBalance} style={{cursor: "pointer"}}/>
    </S.CheckBalance>
  )
}

export default CheckBalance;