import { ENV } from "configs/constraints";
export default function ApiConfig() {
  const configs = {
    development: {
      // BASE_URL: `https://agency-api.sambytech.com/api/v1`, //sambytech
      // BASE_URL: `https://agency-api.conortech.com/api/v1`, //conortech
      // BASE_URL: `https://agency-api.hanenins.com/api/v1`, // hanenins
      // BASE_URL: `https://agency-api.coretechbit.com/api/v1`, // coretechbit
      BASE_URL: `https://agency-api.core-011.com/api/v1`, // core-011
    },
    staging: {
      BASE_URL: process.env.REACT_APP_URL_BE,
    },
    production: {
      BASE_URL: process.env.REACT_APP_URL_BE,
    },
  };

  return configs[ENV];
}
