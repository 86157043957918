const Routes = {
  LOGIN: "/login",
  HOME: "/home",
  REGISTER: "/register",

  ADMIN_SETTLEMENT: "/settlement",
  ADMIN_DISTRIBUTE_HISTORY: "/admin/distribute-history",
  ADMIN_LIST_BUY_COIN: "/admin/list-buy-coin",
  ADMIN_LIST_DELETE_BUY_COIN: "/admin/list-delete-buy-coin",
  ADMIN_USER_BY_COMPANY: "/admin/user-by-company",
  ADMIN_SELL_COIN_REGISTRATION: "/admin/sell-coin-registration",
  ADMIN_STATISTIC: "/admin/statistic",
  ADMIN_DEPOSIT_HISTORY: "/admin/deposit-history",
  ADMIN_BANK_ACCOUNT: "/admin/bank-account",
  ADMIN_REFUND_HISTORY: "/admin/refund-history",
  ADMIN_DOWNLOAD_REFUND: "/admin/download-refund",
  ADMIN_BANK_ACCOUNT_REFUND: "/admin/bank-account-refund",
  ADMIN_MEMBER_MANAGEMENT: "/admin/member-management",
  ADMIN_NEW_MEMBER: "/admin/new-member",
  ADMIN_OLD_MEMBER: "/admin/old-member",
  ADMIN_PENDING_MEMBER: "/admin/pending-member",
  ADMIN_MEMBER_DETAIL: "/admin/member-detail",

  ADMIN_ERRORS: "/admin/errors",
  WHITE_LIST: "/white-list",
  REGISTERED: "/registered",
  REJECTED: "/rejected",
  DOWNLOAD: "/download",
  Navigation: "/navigation",

  TEST: "/admin/test",
  BACKUP_EXECL: "/admin/back-execl",
};

export default Routes;
