import React, { useState, memo, useEffect } from "react";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";

import Avatar from "assets/images/faces/avatar.jpg";

import * as S from "./style";
import { SideBar, Header } from "components";
import { useTranslation } from "react-i18next";
import Routes from "constants/routes";
import { store } from "redux/store";
import { RootState } from "redux/reducer";
import { useSelector } from "react-redux";
// import NotificationSound from "assets/sounds/notification_mp3.mp3";

interface Props {
  boardContent?: any;
  pageHeading?: string;
}

function DefaultLayout(props: Props) {
  const { boardContent, pageHeading } = props;
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(true);
  const { role } = store.getState().user;
  const [dataSidebar, setDataSidebar] = useState([]);
  // console.log(role);
  const dataTotal = useSelector(
    (state: RootState) => state.notification?.dataTotal
  );
  const dataBegin = useSelector(
    (state: RootState) => state.notification?.dataBegin
  );
  const [dataBeginNotice, setDataBeginNotice] = useState({
    total_buy_coin_unread: 0,
    total_sell_coin_unread: 0,
  });

  useEffect(() => {
    setDataBeginNotice(dataBegin);
  }, [dataBegin]);

  // console.log("dataBegin", dataBegin);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const userInfo = {
    avtSrc: Avatar,
    useName: "Tania Andrew",
    subInfo: [
      {
        id: 1,
        codeName: "L",
        pageName: `${t("SIDEBAR_LOGOUT")}`,
        link: "#",
        isActive: false,
      },
    ],
  };

  const dataSidebarPurchasing = [
    // {
    //   titleMenu: `${t("DISTRIBUTE_HISTORY_HEADING")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_DISTRIBUTE_HISTORY,
    //   subMenu: null,
    // },
    {
      titleMenu: `${t("SELL_COIN_REGISTRATION_HEADING")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_SELL_COIN_REGISTRATION,
      subMenu: null,
    },
    {
      titleMenu: `${t("LIST_BUY_COIN")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_LIST_BUY_COIN,
      subMenu: null,
    },

    // {
    //   titleMenu: `${t("STATISTIC_HEADING")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_STATISTIC,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("DOWNLOAD_EXCEL")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_DEPOSIT_HISTORY,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("BANK_ACCOUNT_MANAGEMENT")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_BANK_ACCOUNT,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("REFUND_HISTORY")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_REFUND_HISTORY,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("DOWNLOAD_EXCEL_REFUND")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_DOWNLOAD_REFUND,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("BANK_ACCOUNT_REFUND")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_BANK_ACCOUNT_REFUND,
    //   subMenu: null,
    // },
  ];

  const dataSidebarDev = [
    // {
    //   titleMenu: `${t("DISTRIBUTE_HISTORY_HEADING")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_DISTRIBUTE_HISTORY,
    //   subMenu: null,
    // },
    {
      titleMenu: `${t("LIST_BUY_COIN")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_LIST_BUY_COIN,
      subMenu: null,
    },
    {
      titleMenu: `${t("LIST_DELETE_BUY_COIN")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_LIST_DELETE_BUY_COIN,
      subMenu: null,
    },
    {
      titleMenu: `${t("SELL_COIN_REGISTRATION_HEADING")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_SELL_COIN_REGISTRATION,
      subMenu: null,
    },
    // {
    //   titleMenu: `${t("USER_BY_COMPANY_HEADING")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_USER_BY_COMPANY,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("SETTLEMENT_HEADING")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_SETTLEMENT,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("STATISTIC_HEADING")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_STATISTIC,
    //   subMenu: null,
    // },
    {
      titleMenu: `${t("DOWNLOAD_EXCEL")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_DEPOSIT_HISTORY,
      subMenu: null,
    },

    {
      titleMenu: `${t("BANK_ACCOUNT_MANAGEMENT")}`,
      icon: <ControlCameraIcon />,
      link: Routes.ADMIN_BANK_ACCOUNT,
      subMenu: null,
    },
    {
      titleMenu: `${t("MEMBER_MANAGEMENT")}`,
      icon: <ControlCameraIcon />,
      link: "#",
      subMenu: [
        {
          titleMenu: `${t("PENDING_MEMBER")}`,
          icon: <ControlCameraIcon />,
          link: Routes.ADMIN_PENDING_MEMBER,
          subMenu: null,
        },
        {
          titleMenu: `${t("NEW_MEMBER")}`,
          icon: <ControlCameraIcon />,
          link: Routes.ADMIN_NEW_MEMBER,
          subMenu: null,
        },
        {
          titleMenu: `${t("OLD_MEMBER")}`,
          icon: <ControlCameraIcon />,
          link: Routes.ADMIN_OLD_MEMBER,
          subMenu: null,
        },
      ],
    },
    // {
    //   titleMenu: `${t("REFUND_HISTORY")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_REFUND_HISTORY,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("DOWNLOAD_EXCEL_REFUND")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_DOWNLOAD_REFUND,
    //   subMenu: null,
    // },
    // {
    //   titleMenu: `${t("BANK_ACCOUNT_REFUND")}`,
    //   icon: <ControlCameraIcon />,
    //   link: Routes.ADMIN_BANK_ACCOUNT_REFUND,
    //   subMenu: null,
    // },
  ];

  useEffect(() => {
    if (role === "PURCHASING_AGENT") {
      setDataSidebar(dataSidebarPurchasing);
    } else {
      setDataSidebar(dataSidebarDev);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <S.Wrapper>
      <SideBar userInfo={userInfo} sideBarMenu={dataSidebar} open={open} />
      <main className="main-content">
        <Header
          pageHeading={pageHeading}
          handleDrawerOpen={handleDrawerOpen}
          isOpen={open}
        />
        <div className="board-content">{boardContent}</div>
      </main>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
