import styled from 'styled-components'

export const Popup = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 260px;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before{
    content: "";
    width: 100%;
    height: 100%;
    opacity: .8;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
  }
  .popup-form{
    z-index: 11;
    position: relative;
    width: 380px;
    margin: auto;
    padding: 45px 48px 55px;
  }
  .popup-title{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #000000;
  }
  .popup-content{
    font-size: 16px;
    color: #8d8d8d;
    text-align: center;
  }
  .popup-block{
    width: 100%;
  }
  .address-info{
    padding: 15px 14px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.09);
    /* margin-bottom: 20px; */
    font-size: 14px;
  }
  .select-amount{
    display: flex;
    flex-wrap: wrap;
    margin: 30px -5px;
    .w-25{
      flex: 0 0 25%;
      max-width: 25%;
      padding: 5px;
      &.active{
        .btn-amount{
          background-color: rgba(0,0,0,0.09);
        }
      }
    }
    .btn-amount{
      border: 1px #eaeaea solid;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0;
    }
  }
  .input-amount{
    width: 100%;
  }
  .popup-action{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-popup{
    padding: 10px 20px;
    box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%) !important;
    background-color: #00acc1 !important;
    color: #ffffff;
    width: 100%;
    height: 50px;
    font-size: 14px;
  }
  .btn-close{
    position: absolute;
    top: 0;
    right: 0;
    svg{
      font-size: 30px;
    }
  }
`