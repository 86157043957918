import { createGlobalStyle } from "styled-components";
import { Themes } from "configs/theme";

const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body {
    /* overflow: hidden; */
    background-color: #eeeeee !important;
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    background-color: #eeeeee;
    height: 100%;
  }

  a{
    &:hover{
      text-decoration: none;
    }
  }

  p,
  label {
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }
  
  .image {
    max-width: 100%;
    object-fit: cover;
  }

  img{
    display: block;
  }
  
  .ant-form-item-children-icon {
    top: 50% !important;
  }
  .login__logo-block {
    //es
    background-color: ${Themes["default"].containerBg};
  }
  .ant-menu-vertical .ant-menu-item {
    margin: 0 auto;
  }
  .ant-menu-inline .ant-menu-item {
    margin: 0 auto;
  }
  .anticon {
    vertical-align: 0.2em !important;
  }
  tr.ant-table-expanded-row > td {
    background: ${Themes["default"].mainWhite};
  }
  .white-list__modal-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .input__label {
    color: ${Themes["default"].inputLabel};
  }
  .ant-layout-sider {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
  .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }

  .icon-search{
    margin:  0 10px;
  }

  .date-filter-option{
    min-width: 200px !important;
    height: 34px !important;
  }

  .custom-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-pagination{
      padding: 10px 20px;
      box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%) !important;
      background-color: #00acc1 !important;
      color: #ffffff;
      height: 36px;
      margin: 30px 10px;
    }
  }
  .btn-see-more{
    color: #999999 !important;
    padding: 10px !important;
    width: 36px;
  }

  .purchase-info-data{
    display: flex;
    height: 50px;
    padding: 20px;
    background: #eaeaea;
    border-radius: 10px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
    gap: 200px;
    font-size: 15px;
    font-weight: bold;
  }
  .heading-balance{
    display: flex;
    gap: 5px;
  }
  .notification{
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
    font-size: 14px;
  }
  .cs-tab{
    margin-bottom: 30px;
    .MuiTab-wrapper{
      font-size: 16px;
      color: #000000;
      font-weight: bold
    }
  }
  .default-btn {
    padding: 10px 20px;
    box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%),
      0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%) !important;
    background-color: #00acc1 !important;
    color: #ffffff !important;
    height: 36px;
  }
  .warning-btn {
    padding: 10px 20px;
    box-shadow: 0 12px 20px -10px rgb(0 172 193 / 28%),
      0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 172 193 / 20%) !important;
    background-color: red !important;
    color: #ffffff !important;
    height: 36px;
  }
  .yellow-btn {
    padding: 10px 20px;
    box-shadow: 0 12px 20px -10px rgb(255 117 0 / 28%),
      0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 117 0 / 20%) !important;
    background-color: #ffb100 !important;
    color: #ffffff !important;
    height: 36px;
  }
`;

export default GlobalStyle;
