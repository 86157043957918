import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../redux/store";

import { getBeginNotice, getTotalElement } from "services/User";

interface NotificationState {
  dataTotal: {
    total_buy_coin: number;
    total_sell_coin: number;
  };
  dataBegin: {
    total_buy_coin_unread: number;
    total_sell_coin_unread: number;
    total_new_user_unread: number;
  };
  dataBeginPrev: {
    total_buy_coin_unread: number;
    total_sell_coin_unread: number;
    total_new_user_unread: number;
  };
}

const initialState: NotificationState = {
  dataTotal: {
    total_buy_coin: 0,
    total_sell_coin: 0,
  },
  dataBegin: {
    total_buy_coin_unread: 0,
    total_sell_coin_unread: 0,
    total_new_user_unread: 0,
  },
  dataBeginPrev: {
    total_buy_coin_unread: 0,
    total_sell_coin_unread: 0,
    total_new_user_unread: 0,
  },
};

const notification = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setDataTotal(state: any, action) {
      state.dataTotal = action.payload;
    },
    setDataBegin(state: any, action) {
      state.dataBegin = action.payload;
    },
    setDataBeginPrev(state: any, action) {
      state.dataBegin = action.payload;
    },
  },
});

export const { setDataTotal, setDataBegin } = notification.actions;

export const getDataTotal = (): AppThunk => async (dispatch) => {
  try {
    const response: any = await getTotalElement();
    // console.log(response);
    if (response?.result) {
      dispatch(setDataTotal(response?.data));
    }
  } catch (error) {
    console.log("getDataTotal error", error);
  }
};

export const getDataBegin = (): AppThunk => async (dispatch) => {
  try {
    const response: any = await getBeginNotice();
    if (response?.result) {
      dispatch(setDataBegin(response?.data));
    }
  } catch (error) {
    console.log("getDataTotal error", error);
  }
};

export default notification.reducer;
