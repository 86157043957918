import axios from "services/axiosInstance";
import Endpoints from "constants/endpoints";

export interface User {
  // userid: string,
  role: string;
  accessToken: string;
  email: string;
}

export interface UserResult {
  success: boolean | null;
  data: User;
  msgCode: string | null;
  errors: object | null;
}

export async function userLogin(formData: object): Promise<UserResult> {
  try {
    const response = await axios().post(Endpoints.LOGIN, formData);
    console.log("response12345", response);
    return {
      success: response["result"],
      data: {
        // userid: response.data['userid'],
        accessToken: response.data["accessToken"],
        role: response.data["role"],
        email: response.data["uid"],
      },
      msgCode: response["msgCode"],
      errors: response["errors"],
    };
  } catch (error) {
    throw error;
  }
}

export async function getTotalElement() {
  return await axios()
    .post(Endpoints.GET_TOTAL_ELEMENT)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function getBeginNotice() {
  return await axios()
    .post(Endpoints.GET_BEGIN_NOTICE)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function readBuyCoin() {
  return await axios()
    .post(Endpoints.READ_BUY_COIN)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function readSellCoin() {
  return await axios()
    .post(Endpoints.READ_SELL_COIN)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function readNewUser() {
  return await axios()
    .post(Endpoints.READ_NEW_USER)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}

export async function changePassword(formData?: any) {
  return await axios()
    .post(Endpoints.CHANGE_PASSWORD, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}
