const Endpoints = {
  DOWNLOAD: "/report",
  LOGIN_TWITTER: "/login-twitter",
  GET_ADMIN_DEPOSIT: "/api/users/page",
  WHITE_LIST_ACTION: "/api/email/whitelisted",
  REJECT_ACTION: "/api/email/rejected",
  GET_WHITELIST: "/api/whitelisted/unregistered",
  GET_NEW_WHITELIST: "/api/whitelisted/query",
  CREATE_WHITELIST_EDM: "/api/whitelisting",
  REJECT_FROM_WHITELIST: "/api/email/rejected/from/whitelisted",
  SEARCH_USER: "/api/user/search",

  LOGIN: "/user/login",

  GET_DISTRIBUTE_BY_COMPANY: "/distribute/list-distribute-by-company",
  GET_SETTLEMENT: "/settlement/list",
  GET_LIST_BY_DEV: "/wallet-pool/list-by-dev",
  GET_LIST_SELL_COIN: "/sell-coin/list-sell-coin",
  CHANGE_STATUS_SELL_COIN: "/sell-coin/change-status-sell-coin",
  CHANGE_STATUS_BUY_COIN: "/buy-coin/change-status-buy-coin",
  GET_STATISTIC: "/settlement/statistic-distribute",
  DELETE_DATA_SELL_COIN: "/sell-coin/delete",
  DELETE_DATA_BUY_COIN: "/buy-coin/delete",
  GET_DISTRIBUTE_BALANCE: "/user/balance-of",
  GET_USER: "/user/purchase/me",

  GET_LIST_BUY_COIN: "/buy-coin/list-buy-coin",
  SEND_BUY_COIN: "/buy-coin/transfer-coin",
  RESEND_BUY_COIN: "/buy-coin/transfer-user-to-company",

  DOWNLOAD_EXCEL: "/distribute/download-excel",

  GET_LIST_BANK_ACCOUNT: "/bank/gets",
  GET_LIST_BANK_NAME: "/bank/list-bank-code",
  CREATE_BANK_ACCOUNT: "/bank/create",
  UPDATE_BANK_ACCOUNT: "/bank/update",
  DELETE_BANK_ACCOUNT: "/bank/delete",
  DEFAULT_BANK_ACCOUNT: "/bank/change-default",

  DOWNLOAD_EXCEL_REFUND: "/refund/download-excel",
  GET_REFUND_USER: "/user/purchase-refund/me",
  GET_ALL_REFUND_HISTORY: "/refund/list-refund-by-company",

  GET_LIST_BANK_ACCOUNT_REFUND: "/bank-refund/gets",
  GET_LIST_BANK_NAME_REFUND: "/bank-refund/list-bank-code",
  CREATE_BANK_ACCOUNT_REFUND: "/bank-refund/create",
  UPDATE_BANK_ACCOUNT_REFUND: "/bank-refund/update",
  DELETE_BANK_ACCOUNT_REFUND: "/bank-refund/delete",

  GET_API_BANK_CODE_SERVICE: "/bank/list-bank-code-service",
  GET_API_BANK_CODE_SERVICE_REFUND: "/bank-refund/list-bank-code-service",

  GET_TOTAL_ELEMENT: "/user/total-element",
  GET_BEGIN_NOTICE: "/user/begin-notice",
  READ_BUY_COIN: "/user/read-buy-coin",
  READ_SELL_COIN: "/user/read-sell-coin",
  READ_NEW_USER: "/user/read-new-user",

  GET_NEW_MEMBER: "/user/get-all-new-member",
  GET_PENDING_MEMBER: "/user/get-member-not-yet-approve",
  GET_MEMBER_DETAIL: "/user/get-info",

  GET_TRANSACTION_BUY_COIN: "/user/transaction-buy-coin",
  GET_TRANSACTION_SELL_COIN: "/user/transaction-sell-coin",
  GET_ALL_BANK_ACCOUNT: "/bank/get-all-bank-account",
  UPDATE_MEMBER_DETAIL: "/user/update",
  APPROVE_MEMBER: "/user/approve",
  REJECT_MEMBER: "/user/reject",
  DELETE_MEMBER: "/user/delete",
  ARS_VERIFY: "/user/phoneAuthRequest",
  CHANGE_PASSWORD: "/user/change-password",

  GET_LIST_DELETE_BUY_COIN: "/buy-coin/list-buy-coin-deleted",
  DELETE_PERMANENTLY_BUY_COIN: "/buy-coin/delete-permanently",
  RESTORE_BUY_COIN: "/buy-coin/restore",
};

export default Endpoints;
