import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as S from "./style";

interface Props {
  icon?: any;
  selectedDate?: any;
  onChange?: any;
  classCustom?: string;
  type?: string;
  startDate?: any;
  endDate?: any;
  isDiable?: boolean;
  minDate?: any;
  maxDate?: any;
}

function DateTimePicker(props: Props) {
  const {
    icon,
    selectedDate,
    onChange,
    classCustom,
    type,
    startDate,
    endDate,
    isDiable,
    minDate,
    maxDate,
  } = props;
  const handleDateChange = () => {
    if (typeof onChange === "function") {
      onChange();
    }
  };

  if (type === "startDate") {
    return (
      <S.DateTimePicker>
        <DatePicker
          selected={selectedDate}
          dateFormat="dd/MM/yyyy"
          onChange={handleDateChange}
          className={`date-picker-custom ${classCustom} ${
            isDiable && "disable"
          }`}
          startDate={startDate}
          endDate={endDate}
          selectsStart
          disabled={isDiable || true}
          maxDate={maxDate}
          {...props}
        />
        {icon}
      </S.DateTimePicker>
    );
  } else if (type === "endDate") {
    return (
      <S.DateTimePicker>
        <DatePicker
          selected={selectedDate}
          dateFormat="dd/MM/yyyy"
          onChange={handleDateChange}
          className={`date-picker-custom ${classCustom} ${
            isDiable && "disable"
          }`}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          selectsEnd
          disabled={isDiable}
          maxDate={maxDate}
          {...props}
        />
        {icon}
      </S.DateTimePicker>
    );
  } else {
    return (
      <S.DateTimePicker>
        <DatePicker
          selected={selectedDate}
          dateFormat="dd/MM/yyyy"
          onChange={handleDateChange}
          className={`date-picker-custom ${classCustom} ${
            isDiable && "disable"
          }`}
          disabled={isDiable}
          maxDate={maxDate}
          {...props}
        />
        {icon}
      </S.DateTimePicker>
    );
  }
}

export default DateTimePicker;
