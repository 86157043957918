import React, { useState } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import * as S from "./style";
import { useTranslation } from "react-i18next";

interface Props {
  handleCancel?: () => void;
  handleConfirm?: () => void;
  titlePopup?: string;
  dataPopup?: any;
  isCancel?: boolean;
  isConfirm?: boolean;
  isAction?: boolean;
}

function PopupAlert(props: Props) {
  const {
    handleCancel,
    handleConfirm,
    titlePopup,
    dataPopup,
    isCancel,
    isConfirm,
    isAction,
  } = props;
  const { t } = useTranslation("common");
  const [disabled, setDisabled] = useState(false);

  return (
    <S.Popup>
      <Card className="popup-form">
        <CardContent>
          <IconButton
            color="default"
            onClick={handleCancel}
            edge="start"
            className="btn-close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            className="popup-title"
            color="textSecondary"
            gutterBottom
          >
            {titlePopup}
          </Typography>
          <div
            className="popup-content"
            color="textSecondary"
            // gutterBottom
          >
            {dataPopup}
          </div>
        </CardContent>
        <CardActions className="popup-action">
          {isCancel && (
            <Button onClick={handleCancel} className="btn-popup">
              {t("BTN_CANCEL")}
            </Button>
          )}
          {isConfirm && (
            <Button
              onClick={() => {
                handleConfirm();
                setDisabled(true);
              }}
              className="btn-popup"
              disabled={disabled}
            >
              {t("BTN_CONFIRM")}
            </Button>
          )}
          {isAction && (
            <Button
              onClick={() => {
                handleConfirm();
                setDisabled(true);
              }}
              className="btn-popup"
              disabled={disabled}
            >
              {t("SAVE")}
            </Button>
          )}
        </CardActions>
      </Card>
    </S.Popup>
  );
}

export default PopupAlert;
