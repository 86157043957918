import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
height: 100%;
.board-content{
  padding: 50px 30px;
  margin-top: 70px;
  margin-bottom: 50px;
  background-color: #ffffff;
  border-radius: 10px;
}

.main-content{
  transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  flex-grow: 1;
  padding: 0 30px;
  position: relative;
}

`