import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import userReducer from "features/user/userSlice";
import notificationReducer from "features/user/notificationSlice";
import newUsersReducer from "features/newUsers/newUsersSlice";
import whitelistReducer from "features/whitelist/whiteListSlice";
import filterReducer from "features/filter/filterSlice";
import history from "utils/history";

const combinedReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  newUsers: newUsersReducer,
  whitelist: whitelistReducer,
  notification: notificationReducer,
  filter: filterReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "logOut") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
